import React, {useEffect, useRef, useState} from 'react';
import './App.css';
import content from './assests/content(1).png';
import circles from './assests/circles.png';

function App() {

    const [lastScrollY, setLastScrollY] = useState<number>(0);
    const [ticking, setTicking] = useState<boolean>(false);

    const ref = useRef<HTMLImageElement>(null);

    const handleScroll = () => {
        setLastScrollY(Number(window.scrollY))

    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    useEffect(() => {
        window.requestAnimationFrame(() => {
            if (ref.current !== null) {
                ref.current.style.transform = `translateY(${lastScrollY / 10}px)`
            }
        });

    }, [lastScrollY])

    return (
        <div className="App">

            <div className={'back'}/>

            <img src={content} className={'content'} alt={'content'}/>
            <img src={circles} className={'circles'} alt={'circles'} ref={ref}/>

            <div className={'text'}>
                <p>
                    <p>
                        Тадбир сухандонлари:
                    </p>
                    <p>
                        Аваз Аманов, Одинабону Эргашева
                    </p>
                </p>
                <p>
                    <p>
                        “Заковат” интеллектуал клуби асосчиси ва
                        доимий бошловчиси:
                    </p>
                    <p>
                        Абдурасул Абдуллаев
                    </p>
                </p>
                <p>
                    <p>
                        Бош режиссёр:
                    </p>
                    <p>
                        Нишанов Равшан
                    </p>
                </p>
                <p>
                    <p>
                        Режиссёр ва сценарий
                        муаллифи:
                    </p>
                    <p>
                        Динара Ачилдиева
                    </p>

                </p>
                <p>
                    <p> Овоз режиссёрлари:</p>
                    <p>
                        Феруз Норкучкаров
                        Ринат Абубакиров
                    </p>
                </p>
                <p>
                    <p> Экран ва чироқ режиссёри:
                    </p>
                    <p> Ринат Абубакиров</p>
                </p>
                <p>
                    DWED
                </p>
                <button onClick={(e: any) => {
                    window.location.href = 'https://t-med.uz/stream';
                }} className={"button"}>Жонли эфир</button>
            </div>
        </div>
    );
}

export default App;
